import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import Dashboard from '../layouts/Dashboard'
import { useRecoilValue } from "recoil";
import { userState } from "../store/userState";
import useSWR from 'swr';
import fetcher from '../services/fetcher';

export default function Home() {
  const user = useRecoilValue(userState)
  const router = useRouter()
  const { data: outletData } = useSWR(`/dashboard/stores?perPage=1`, fetcher)

  useEffect(() => {
    if (outletData?.data && outletData?.data?.length === 0) {
      router.push('/pengaturan/outlet')
    }
  }, [outletData?.data])

  useEffect(() => {
    // router.replace(`/penjualan/dashboard`)
    // if (user?.role?.name === 'Owner') {
    //   router.replace(`/penjualan/dashboard`)
    // }
    // if (user?.role?.name === 'MANAGER') {
    //   router.replace(`/${user?.store}/penjualan/dashboard`)
    // }
    // if (user?.role?.name === 'SUPERADMIN') {
    //   router.replace(`/konten/beranda`)
    // }
  }, [user])

  return (
    <div>
      <Head>
        <title>Home</title>
      </Head>

      <Dashboard>
        {/* {user ? 'Redirecting ...' : 'Home'} */}
        <div className='flex items-center justify-center flex-grow'>
          <div>
            <h1 className='text-xl font-semibold text-gray-700'>Selamat datang <span className='italic text-mkps-primary-light'>{user?.name}</span></h1>
          </div>
        </div>
      </Dashboard>
    </div>
  )
}
